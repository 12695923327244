<template>
  <v-dialog v-model="dialog.display" width="800px" persistent>
    <v-card class="pa-5">
      <v-container>
        <!--Header-->
        <v-row class="mb-3">
          <v-col cols="12">
            <v-spacer></v-spacer
            ><v-btn
              small
              class="float-right"
              icon
              @click="$emit('closeSeoModal')"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
            <h3>Edit SEO tags</h3>
          </v-col>
        </v-row>
        <!--Validation error-->
        <v-row v-if="validationError">
          <v-col cols="12">
            <v-alert text dense type="error"
              >One or more fields are invalid</v-alert
            >
          </v-col>
        </v-row>
        <!--Fields-->
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <strong>Page Title</strong>
              <v-text-field
                outlined
                dense
                v-model="pageTitle"
                :counter="70"
                :rules="titleRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <strong>Meta Description</strong>
              <v-textarea
                outlined
                dense
                v-model="metaDescription"
                :counter="320"
                :rules="descriptionRule"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <strong>URL Path</strong>
              <v-text-field
                outlined
                dense
                v-model="newUrl"
                :rules="pathRule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <!--Action Buttons-->
        <v-row>
          <v-col cols="12">
            <v-spacer></v-spacer>
            <v-btn class="float-right" color="primary" @click="validate()"
              >Save SEO tags</v-btn
            >
            <v-btn class="float-right" text @click="$emit('closeSeoModal')"
              ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { mapGetters } from "vuex";
export default {
  name: "SEOFieldsModal",
  props: ["dialog", "title", "description", "path"],
  data() {
    return {
      validationError: false,
      valid: true,
      pageTitle: this.title,
      metaDescription: this.description,
      newUrl: this.path,
      titleRule: [
        (v) => !!v || "This field can't be blank",
        (v) => v.length <= 70 || `Can't be more than 70 characters long`,
      ],
      descriptionRule: [
        (v) => !!v || "This field can't be blank",
        (v) => v.length <= 320 || `Can't be more than 320 characters long`,
      ],
      pathRule: [
        (v) => !!v || "This field can't be blank",
        (v) =>
          !this.filteredUrls.includes(v) ||
          `URL already belongs to another product`,
        (v) => v.length <= 70 || `Can't be more than 70 characters long`,
        (v) => v.slice(0, 1) === "/" || `Path must start with a '/'`,
        (v) => {
          const specialChars = /[`!@#$%^&*()_+=[{};':"|,.<>?~\\|]/;
          return (
            !specialChars.test(v) || `Path can't contain any special characters`
          );
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["urlList"]),
    filteredUrls() {
      return this.urlList.filter((el) => el !== this.path);
    },
  },
  store,
  methods: {
    validate() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.validationError = true;
        return;
      }
      this.validationError = false;
      this.$emit("saveSeoTags", {
        seoTitle: this.pageTitle,
        seoDescription: this.metaDescription,
        newUrl: this.newUrl,
      });
      this.$emit("closeSeoModal");
    },
  },
};
</script>